/* eslint-disable import/no-extraneous-dependencies */
// Info: these imports are needed for storybook
import React from 'react';
import PropTypes from 'prop-types';
import classes from './Card.module.css';

const Card = ({ children }) => {
  return <div className={classes.card}>{children}</div>;
};

Card.propTypes = {
  children: PropTypes.instanceOf(Object),
};

Card.defaultProps = { children: {} };

export default Card;
