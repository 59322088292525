/* eslint-disable no-plusplus */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import classes from './SynthesePage.module.css';
import DynamicSchedulesForm from '../components/content/DynamicSchedulesForm';
import Card from '../components/ui/Card/Card';
import {
  scheduleParser,
  buildTransactionSets,
  buildDependencySet,
  scheduleAnomalyTest,
  printTransactionSet,
  printSchedule,
} from '../helper/schedulesHelper';
import makeTopoSort from '../helper/TopoSort';

function SchedulesPage() {
  const [result, setResult] = useState();

  const myFunc = (data) => {
    let resultString = '';
    const transactionSets = [];
    const dependencySets = [];

    for (let i = 0; i < data.DynamicSchedulesForm.length; i++) {
      resultString += `Schedule ${i + 1}:\n${
        data.DynamicSchedulesForm[i].Schedule
      }\n\n`;
      const schedule = scheduleParser(data.DynamicSchedulesForm[i].Schedule);
      const transactionSet = buildTransactionSets(schedule);
      transactionSets.push(transactionSet);
      const dependencySet = buildDependencySet(schedule);
      dependencySets.push(dependencySet);
      const scheduleAnomalies = scheduleAnomalyTest(schedule);
      const transactionSetStr = printTransactionSet(transactionSet);
      const dependencySetStr = printSchedule(dependencySet);
      const isAzyklisch = makeTopoSort(dependencySet);
      resultString += `Transaction-Set:\n${transactionSetStr}\n\n`;
      resultString += `Dependency-Set:\n${dependencySetStr}\n\n`;
      resultString += `Anomalies:\n${scheduleAnomalies}\n`;

      if (isAzyklisch) {
        resultString += 'Dieser Schedule ist Azyklisch!\n';
      } else {
        resultString += 'Dieser Schedule ist NICHT Azyklisch!\n';
      }

      if (i !== data.DynamicSchedulesForm.length - 1) {
        resultString += '______________________________________\n\n';
      }
    }
    let conflictEquivalent = true;
    let spacer = true;
    for (let i = 0; i < transactionSets.length; i++) {
      for (let j = i + 1; j < transactionSets.length; j++) {
        if (
          JSON.stringify(transactionSets[i]) !==
          JSON.stringify(transactionSets[j])
        ) {
          if (spacer) {
            spacer = false;
            resultString += '______________________________________\n\n';
          }
          conflictEquivalent = false;
          resultString += `Transaction S${i} & S${j} are not conflict equivalent!\n`;
        }
      }
    }

    for (let i = 0; i < dependencySets.length; i++) {
      for (let j = i + 1; j < dependencySets.length; j++) {
        if (
          JSON.stringify(dependencySets[i]) !==
          JSON.stringify(dependencySets[j])
        ) {
          if (spacer) {
            spacer = false;
            resultString += '______________________________________\n\n';
          }
          conflictEquivalent = false;
          resultString += `Dependency-Set of S${i} & S${j} are not conflict equivalent!\n`;
        }
      }
    }

    if (conflictEquivalent && dependencySets.length > 1) {
      resultString += '______________________________________\n\n';
      resultString += 'Schedules are conflict equivalent!';
    }
    setResult(resultString);
  };

  return (
    <section>
      <h1>Schedules:</h1>
      <Card>
        <DynamicSchedulesForm myFunc={myFunc} />
      </Card>
      {result ? (
        <Card>
          <div className={classes.result}>
            <h2>Result:</h2>
            <pre>{result}</pre>
          </div>
        </Card>
      ) : null}
    </section>
  );
}
export default SchedulesPage;
