import React from 'react';
import { Route, Switch } from 'react-router-dom';
import SchedulesPage from './pages/SchedulesPage';
import SynthesePage from './pages/SynthesePage';
import HomePage from './pages/HomePage';
import Layout from './components/layout/Layout';

function App() {
  return (
    <div>
      <Layout>
        <Switch>
          <Route path="/" exact>
            <HomePage />
          </Route>
          <Route path="/synthese">
            <SynthesePage />
          </Route>
          <Route path="/schedules">
            <SchedulesPage />
          </Route>
        </Switch>
      </Layout>
    </div>
  );
}

export default App;
