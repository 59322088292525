/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef } from 'react';
import { useForm, useFieldArray, useWatch } from 'react-hook-form';
import classes from './DynamicSchedulesForm.module.css';

function DynamicSchedulesForm({ myFunc }) {
  const { control, register, handleSubmit } = useForm();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'DynamicSchedulesForm',
  });

  function submitHandler(data) {
    myFunc(data);
  }
  return (
    <>
      <form
        className={classes.form}
        onSubmit={handleSubmit(submitHandler, () => {
          throw new Error('Something is wrong');
        })}
      >
        {fields.map(({ id }, index) => {
          return (
            <div key={id} className={classes.container}>
              <div>
                <h2>{`S${index + 1}:  `}</h2>
              </div>
              <div className={classes.control}>
                <input
                  type="text"
                  placeholder="e.g.: (r2(y),r1(y),r1(x),w2(y)"
                  {...register(`DynamicSchedulesForm[${index}].Schedule`)}
                />
              </div>
              <div className={classes.actions}>
                <button type="button" onClick={() => remove(index)}>
                  remove
                </button>
              </div>
            </div>
          );
        })}
        <div className={classes.addActions}>
          <button type="button" onClick={() => append({})}>
            Add Schedule
          </button>
        </div>
        <div className={classes.runActions}>
          <button type="submit">Calculate Conflicts</button>
        </div>
      </form>
    </>
  );
}

export default DynamicSchedulesForm;
