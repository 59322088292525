/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef } from 'react';
import { useForm, useFieldArray, useWatch } from 'react-hook-form';
import classes from './DynamicSyntheseForm.module.css';

function DynamicSyntheseForm({ syntheseAlgo }) {
  const { control, register, handleSubmit } = useForm();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'DynamicSyntheseForm',
  });

  function submitHandler(data) {
    syntheseAlgo(data);
  }
  return (
    <>
      <form
        className={classes.form}
        onSubmit={handleSubmit(syntheseAlgo, () => {
          throw new Error('Something is wrong');
        })}
      >
        {fields.map(({ id }, index) => {
          return (
            <div key={id} className={classes.container}>
              <div>
                <h3 className={classes.arrow}>{`Dep. ${index + 1}:  `}</h3>
              </div>
              <div className={classes.control}>
                <input
                  type="text"
                  placeholder="attributes | e.g.: a,d"
                  {...register(`DynamicSyntheseForm[${index}].left`)}
                />
              </div>
              <h3 className={classes.arrow}>🢂</h3>
              <div className={classes.control}>
                <input
                  type="text"
                  placeholder="dependencies | e.g.: b,c"
                  {...register(`DynamicSyntheseForm[${index}].right`)}
                />
              </div>
              <div className={classes.actions}>
                <button type="button" onClick={() => remove(index)}>
                  remove
                </button>
              </div>
            </div>
          );
        })}
        <div className={classes.addActions}>
          <button type="button" onClick={() => append({})}>
            Add Dependency (FD)
          </button>
        </div>
        <div>
          <div>Berechne Attributhülle von Dependency:</div>
          <div className={classes.control}>
            <input
              type="text"
              placeholder="e.g.: a,d (requires a set of dependencies!)"
              {...register(`AttributeHull`)}
            />
          </div>
        </div>
        <div className={classes.runActions}>
          <button type="submit">Run Synthese</button>
        </div>
      </form>
    </>
    // TODO: Button and field for Attributshüllen calculation.
  );
}

export default DynamicSyntheseForm;
