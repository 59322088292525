/* eslint-disable no-loop-func */

/* eslint-disable no-plusplus */
function createVertecies(dependencySet) {
  const vertecies = [];

  for (let i = 0; i < dependencySet.length; i++) {
    const startEnd = dependencySet[i].transaction.split(',');
    vertecies.push({ start: startEnd[0], end: startEnd[1] });
  }

  return vertecies;
}

function makeTopoSort(dependencySet) {
  const vertecies = createVertecies(dependencySet);
  let N = 1;
  // Bestimme din(v) ∀v ∈ V ;
  const dIn = new Map();
  for (let i = 0; i < vertecies.length; i++) {
    if (!dIn.has(vertecies[i].start)) {
      dIn.set(vertecies[i].start, 0);
    }
    if (!dIn.has(vertecies[i].end)) {
      dIn.set(vertecies[i].end, 1);
    } else {
      dIn.set(vertecies[i].end, dIn.get(vertecies[i].end) + 1);
    }
  }

  let L = [];
  // L = dIn.filter((entry) => entry.value === 0);
  dIn.forEach((value, key) => {
    // eslint-disable-next-line radix
    if (value === 0) L.push(parseInt(key));
  });

  while (L.length > 0) {
    const v = L[0];
    L = L.filter((elem) => elem !== v);
    // Topo.push(v);
    N += 1;
    vertecies.forEach((elem) => {
      // eslint-disable-next-line eqeqeq
      if (elem.start == v) {
        dIn.set(elem.end, dIn.get(elem.end) - 1);
        if (dIn.get(elem.end) === 0) {
          L.push(elem.end);
        }
      }
    });
  }

  const vertexSet = new Set();
  for (let i = 0; i < vertecies.length; i++) {
    vertexSet.add(vertecies[i].start);
    vertexSet.add(vertecies[i].end);
  }

  if (N - 1 === Array.from(vertexSet).length) {
    return true;
  }
  return false;
}

export default makeTopoSort;
