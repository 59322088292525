/* eslint-disable eqeqeq */
/* eslint-disable no-plusplus */
function scheduleToObject(inputStr) {
  const operation = inputStr[0];
  const transaction = inputStr.substring(1, inputStr.indexOf('('));
  const variable = inputStr.substring(
    inputStr.indexOf('(') + 1,
    inputStr.lastIndexOf(')'),
  );

  return { operation, transaction, variable };
}

function scheduleParser(inputStr) {
  const inputArray = inputStr.split(' ').join('').split(',');
  const schedule = [];
  for (let i = 0; i < inputArray.length; i++) {
    const obj = scheduleToObject(inputArray[i]);
    schedule.push(obj);
  }

  return schedule;
}

function buildTransactionSets(schedule) {
  const transactionSets = new Map();
  for (let i = 0; i < schedule.length; i++) {
    if (!transactionSets.has(schedule[i].transaction)) {
      transactionSets.set(schedule[i].transaction, [schedule[i]]);
    } else {
      const newValue = transactionSets.get(schedule[i].transaction);

      newValue.push(schedule[i]);
      transactionSets.set(schedule[i].transaction, newValue);
    }
  }

  return transactionSets;
}

function buildDependencySet(schedule) {
  const dependencySet = [];
  for (let i = 0; i < schedule.length; i++) {
    for (let j = i + 1; j < schedule.length; j++) {
      if (
        schedule[i].variable == schedule[j].variable &&
        schedule[i].transaction != schedule[j].transaction &&
        !(
          schedule[i].operation == schedule[j].operation &&
          schedule[i].operation == 'r'
        )
      ) {
        dependencySet.push({
          operation: `${schedule[i].operation}${schedule[j].operation}`,
          transaction: `${schedule[i].transaction},${schedule[j].transaction}`,
          variable: schedule[i].variable,
        });
      }
    }
  }
  return dependencySet;
}

function printAtom(atom) {
  return `${atom.operation}${atom.transaction}(${atom.variable})`;
}

function printSchedule(schedule) {
  let out = '';
  for (let i = 0; i < schedule.length; i++) {
    if (i == schedule.length - 1) {
      out += printAtom(schedule[i]);
    } else {
      out += `${printAtom(schedule[i])}, `;
    }
    if (i % 7 === 0 && i > 0) {
      out += '\n';
    }
  }
  return out;
}

function printTransactionSet(transactionSets) {
  let out = '';
  transactionSets.forEach((value, key) => {
    out += `T${key}: ${printSchedule(value)}\n`;
  });
  return out;
}

function scheduleAnomalyTest(schedule) {
  let anomalys = '';

  for (let i = 0; i < schedule.length; i++) {
    switch (schedule[i].operation) {
      case 'r':
        for (let j = i + 1; j < schedule.length; j++) {
          if (
            schedule[j].variable == schedule[i].variable &&
            schedule[j].transaction != schedule[i].transaction
          ) {
            if (schedule[j].operation == 'w') {
              for (let k = j + 1; k < schedule.length; k++) {
                if (
                  schedule[k].variable == schedule[j].variable &&
                  schedule[k].transaction != schedule[j].transaction &&
                  schedule[k].transaction == schedule[i].transaction
                ) {
                  switch (schedule[k].operation) {
                    case 'r':
                      anomalys += `Non-repeatable Read: ${printSchedule([
                        schedule[i],
                        schedule[j],
                        schedule[k],
                      ])}\n`;
                      break;
                    case 'w':
                      anomalys += `Lost Update: ${printSchedule([
                        schedule[i],
                        schedule[j],
                        schedule[k],
                      ])}\n`;
                      break;
                    default:
                      break;
                  }
                }
              }
            }
          }
        }
        break;
      case 'w':
        for (let j = i + 1; j < schedule.length; j++) {
          if (
            schedule[j].variable == schedule[i].variable &&
            schedule[j].transaction != schedule[i].transaction &&
            schedule[j].operation == 'r'
          ) {
            for (let k = j + 1; k < schedule.length; k++) {
              if (
                schedule[k].variable == schedule[j].variable &&
                schedule[k].transaction != schedule[j].transaction &&
                schedule[k].transaction == schedule[i].transaction &&
                schedule[k].operation == 'w'
              ) {
                anomalys += `Dirty Read: ${printSchedule([
                  schedule[i],
                  schedule[j],
                  schedule[k],
                ])}\n`;
              }
            }
          }
        }
        break;
      default:
        break;
    }
  }
  return anomalys;
}

export {
  scheduleParser,
  buildTransactionSets,
  buildDependencySet,
  scheduleAnomalyTest,
  printTransactionSet,
  printSchedule,
};
