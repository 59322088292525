/* eslint-disable no-bitwise */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-param-reassign */
/* eslint-disable no-loop-func */
/* eslint-disable no-continue */
/* eslint-disable eqeqeq */
/* eslint-disable no-plusplus */
// dependencies = [{left: [], right: []}]

function formParser(data) {
  const dependencies = [];
  for (let i = 0; i < data.length; i++) {
    if (data[i]?.left !== '' && data[i]?.right !== '') {
      const leftArr = data[i].left.split(' ').join('').split(',');
      const rightArr = data[i].right.split(' ').join('').split(',');
      dependencies.push({ left: leftArr, right: rightArr });
    }
  }
  return dependencies;
}

const powerSet = (arr = []) => {
  const res = [];
  const { length } = arr;
  const numberOfCombinations = 2 ** length;
  for (
    let combinationIndex = 0;
    combinationIndex < numberOfCombinations;
    combinationIndex += 1
  ) {
    const subSet = [];
    for (
      let setElementIndex = 0;
      setElementIndex < arr.length;
      setElementIndex += 1
    ) {
      if (combinationIndex & (1 << setElementIndex)) {
        subSet.push(arr[setElementIndex]);
      }
    }
    res.push(subSet);
  }
  return res;
};

function attributeShell(attribute, dependencies) {
  /*
    geg.: n elemente auf einer linken seite.
    ges.: alle Abhängigkeiten von der linken seite.
    */
  let lastResult = new Set();
  const result = new Set();
  attribute.forEach((item) => result.add(item));
  while (
    JSON.stringify(Array.from(result)) != JSON.stringify(Array.from(lastResult))
  ) {
    lastResult = new Set(result);
    // loop through dependencies.
    for (let i = 0; i < dependencies.length; i++) {
      let isIn = false;
      // loop to elements of left side of dependency.
      for (let j = 0; j < dependencies[i].left.length; j++) {
        if (result.has(dependencies[i].left[j])) {
          isIn = true;
        } else if (
          new Set(dependencies[i].right).has(dependencies[i].left[j])
        ) {
          isIn = true;
        } else {
          isIn = false;
          break;
        }
      }
      if (isIn) {
        dependencies[i].right.forEach((item) => result.add(item));
      }
    }
  }
  return result;
}

function sortSet(set) {
  const array = Array.from(set);
  array.sort();
  return new Set(array);
}

// -------- Kanonische Überdeckung -------------------------
function leftReduction(dependencies) {
  /*
    verkleinere die linke seite der AttrHülle solange sich nichts an der rechten ändert.
    */
  for (let i = 0; i < dependencies.length; i++) {
    // attributshülle von der gesammten linken Seite.
    let depAttrShell = attributeShell(dependencies[i].left, dependencies);
    depAttrShell = sortSet(depAttrShell);

    const pSet = powerSet(dependencies[i].left);
    pSet.sort(function (a, b) {
      // ASC  -> a.length - b.length
      // DESC -> b.length - a.length
      return a.length - b.length;
    });
    for (let j = 1; j < pSet.length; j++) {
      const attrShellSet = attributeShell(pSet[j], dependencies);
      // const attrShell = Array.from(attrShellSet);
      let hasAllRight = true;
      for (let r = 0; r < dependencies[i].right.length; r++) {
        if (!attrShellSet.has(dependencies[i].right[r])) {
          hasAllRight = false;
          break;
        }
      }
      if (hasAllRight) {
        dependencies[i].left = pSet[j];
        break;
      }
    }
  }
  return dependencies;
}

function rightReduction(dependencies) {
  /*
    schaue rechts ein Element an
    schaue ob es auf der linken Seite existiert,
    // schaue ob dort auf der rechten seite ein anderes Element von der ursprünglichen rechten seite steht
    entferne es ggf von der ursprünglichen rechten seite.
    */
  const resultingDependencies = JSON.parse(JSON.stringify(dependencies));

  // für jede Abhängigkeit.
  for (let i = 0; i < dependencies.length; i++) {
    let toRemove = [];
    // berechne die Attributhülle (und sortiere sie zum vergleichen).
    let attrShell = attributeShell(
      resultingDependencies[i].left,
      resultingDependencies,
    );
    attrShell = sortSet(attrShell);
    // für jedes abhängige element
    for (let j = 0; j < resultingDependencies[i].right.length; j++) {
      const newDependencies = JSON.parse(JSON.stringify(resultingDependencies));
      // entferne es aus den abhängigkeiten und berechne neue attributHülle.
      newDependencies[i].right = newDependencies[i].right.filter(
        (elem) => elem != resultingDependencies[i].right[j],
      );
      const newAttrShell = sortSet(
        attributeShell(resultingDependencies[i].left, newDependencies),
      );
      // Wenn das entfernte element in der neuen attributhülle ist,
      if (
        Array.from(newAttrShell).includes(resultingDependencies[i].right[j])
      ) {
        // füge es zur liste der zu entfernenden abhängigen elemente
        toRemove = toRemove.concat([resultingDependencies[i].right[j]]);
      }
    }
    // entferne die elemente aus der list tatsächlich aus den abhängigkeiten.
    resultingDependencies[i].right = JSON.parse(
      JSON.stringify(
        resultingDependencies[i].right.filter(
          (elem) => !toRemove.includes(elem),
        ),
      ),
    );
  }
  return resultingDependencies;
  // TODO: built in check if something has changed.
}

function removeRightEmpty(dependencies) {
  return dependencies.filter((elem) => elem.right.length > 0);
}

function sumLeftEqualDependencies(dependencies) {
  let newDep = JSON.parse(JSON.stringify(dependencies));
  for (let i = 0; i < dependencies.length; i++) {
    for (let j = i; j < dependencies.length; j++) {
      if (j == i) {
        continue;
      }

      if (
        JSON.stringify(dependencies[i].left) ===
        JSON.stringify(dependencies[j].left)
      ) {
        if (i < j) {
          newDep = newDep.filter((elem) => newDep.indexOf(elem) != j);
          newDep[i].right = JSON.parse(
            JSON.stringify(dependencies[i].right.concat(dependencies[j].right)),
          );
        } else {
          newDep = newDep.filter((elem) => newDep.indexOf(elem) != i);
          newDep[j].right = dependencies[j].right.concat(dependencies[i].right);
        }
      }
    }
  }
  return newDep;
}
// -------- Neues Relationenschema ---------------------

function createRelations(dependencies) {
  return dependencies;
}

function reconstruateKeyCandidate(dependencies) {
  let keyKandidates = [];
  const attributeSet = new Set();
  // Build set of all attributes.
  // take each dependency
  for (let d = 0; d < dependencies.length; d++) {
    // take each attribute of the dependency
    for (let a = 0; a < dependencies[d].left.length; a++) {
      // add it to the set of all attributes.
      attributeSet.add(dependencies[d].left[a]);
    }
    for (let a = 0; a < dependencies[d].right.length; a++) {
      // add it to the set of all attributes.
      attributeSet.add(dependencies[d].right[a]);
    }
  }
  const AttributeArray = Array.from(attributeSet);

  const power = powerSet(AttributeArray);
  power.sort(function (a, b) {
    // ASC  -> a.length - b.length
    // DESC -> b.length - a.length
    return a.length - b.length;
  });

  for (let i = 0; i < power.length; i++) {
    const powerAttributeHull = attributeShell(power[i], dependencies);
    if (
      JSON.stringify(Array.from(powerAttributeHull).sort()) ===
      JSON.stringify(AttributeArray.sort())
    ) {
      keyKandidates.push(power[i]);
    }
  }
  keyKandidates = keyKandidates.filter(
    (elem) => elem.length == keyKandidates[0].length,
  );
  return keyKandidates;
}

// function eliminateRedundantRelations() {}

export {
  formParser,
  attributeShell,
  leftReduction,
  rightReduction,
  sumLeftEqualDependencies,
  removeRightEmpty,
  createRelations,
  reconstruateKeyCandidate,
};
