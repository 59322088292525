/* eslint-disable no-plusplus */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import classes from './SynthesePage.module.css';
import DynamicSyntheseForm from '../components/content/DynamicSyntheseForm';
import Card from '../components/ui/Card/Card';
import {
  formParser,
  attributeShell,
  leftReduction,
  rightReduction,
  sumLeftEqualDependencies,
  removeRightEmpty,
  reconstruateKeyCandidate,
} from '../helper/normalisierenHelper';

function parseResult(results) {
  let res = '';
  for (let i = 0; i < results.length; i++) {
    res += `${results[i].left} -> ${results[i].right}\n`;
  }
  return res;
}

function SynthesePage() {
  const [result, setResult] = useState();
  const syntheseAlgo = (data) => {
    const dependencies = formParser(data.DynamicSyntheseForm);

    let resultString = '';
    resultString += `Your Input:\n${parseResult(dependencies)}\n`;
    if (data.AttributeHull !== '') {
      const attr = data.AttributeHull.split(' ').join('').split(',');
      resultString += `AttributeHull:\n${JSON.stringify(
        Array.from(attributeShell(attr, dependencies)),
      )}\n___________________________________\n\n`;
    }

    const leftReduct = leftReduction(dependencies);
    const rightReduct = rightReduction(leftReduct);
    const rightEmpty = removeRightEmpty(rightReduct);
    const removedDupl = sumLeftEqualDependencies(rightEmpty);

    resultString += `Left Reduction:\n${parseResult(leftReduct)}\n`;
    resultString += `Right Reduction:\n${parseResult(rightReduct)}\n`;
    resultString += `Remove rightempty:\n${parseResult(rightEmpty)}\n`;
    resultString += `remove Duplicates:\n${parseResult(removedDupl)}\n`;

    const keyKandidates = reconstruateKeyCandidate(dependencies);
    resultString += `Key Kandidates:\n${JSON.stringify(keyKandidates)}\n`;

    setResult(resultString);
  };

  return (
    <section>
      <h1>Synthese Algorithmus</h1>
      <Card>
        <DynamicSyntheseForm syntheseAlgo={syntheseAlgo} />
      </Card>
      {result ? (
        <Card>
          <div className={classes.result}>
            <h2>Result:</h2>
            <pre>{result}</pre>
          </div>
        </Card>
      ) : null}
    </section>
  );
}
export default SynthesePage;
