import React from 'react';
import Card from '../components/ui/Card/Card';
import classes from './HomePage.module.css';

function HomePage() {
  return (
    <section>
      <h1>HOME SWEET HOME</h1>

      <Card>
        <div className={classes.mypad}>
          <div>
            Dies ist eine Sammlung von sinnvollen Tools zum Bestehen der InfoSys
            Klausur an der CAU.
          </div>
          <div>Externe Seiten:</div>
          <a href="https://www.cs.usfca.edu/~galles/visualization/BTree.html">
            B-Bäume
          </a>
          <div>
            <a href="https://normalizer.db.in.tum.de/index.py">
              Synthese Extern
            </a>
          </div>
        </div>
      </Card>
    </section>
  );
}

export default HomePage;
